@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@primary-color: #1F3E76;
@layout-header-background: #f8fcff;
@layout-body-background: #f0f2f5;
@layout-trigger-background: #f8fcff;
@layout-trigger-color: rgb(24, 23, 23);
//@layout-header-background: #b2c0cb;

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #E4E4E4 #FFFFFF;
  }
  
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 7px;
    width: 7px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #E4E4E4;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #E4E4E4;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #E4E4E4;
  }
  
body{
    font-family: "Poppins","DM Sans", "sans-serif";
}

.ant-layout-header{
    background:#f5f5f5
}

.ant-list-item {
    flex-direction: "column";
    padding: 5px 0;
    border-bottom: 2px solid #fff;
}

.ant-list-items {
    margin: 0;
    /* background-color: blue; */
    // width: 600px;
    padding: 5px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: space-around;
    background-color: #ffffff;
    border-radius: 10px;
}

.company-info-wrapper{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.company-title {
    font-size: 10px;
    color: #888888;
}

.company-user{
    color: #5C5C5C;
    font-weight: 600;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    span{
        font-size: 11px;
        font-weight: 400;
        font-style: italic;
    }
}

.user-msg {
    color: black;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
}

.post-description{
    color: black;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
}

.post-bottom-div{
    display: grid;
    grid-template-columns: 2fr 1fr;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    p{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.read-or-hide{
    color: rgb(192,192,192);
    cursor: pointer;
  }
.user-title {
    color: #1F3E76;
    font-size: 10px;
    font-weight: 600;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-date {
    font-size: 9px;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #667781;
    text-align: right;
}

.paymentWrp{
    font-size: 14px;
    padding: 7px 15px 7px 15px;
    border-radius: 4px 17px 17px 17px;
    color: black;
    font-weight: bold;
    width: 280px;
    background: white;
    .payamnt{
        background: #F5F5F5;
        border-radius: 7px;
        width: fit-content;
        padding: 5px 17px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
    }
    .payinitbtn{
        padding: 10px 25px;
        background: #FFBB1D;
        border-radius: 46px;
        width: fit-content;
        margin: 20px 0;
        display: flex;
        align-items: center;
        column-gap: 20px;
        p{
            margin: 0;
        }
        .reloadicon{
            font-size: 20px;
            cursor: pointer;
        }
    }
    .paysuccessbtn{
        padding: 5px 25px;
        background:  #29CC6A;
        color: white;
        border-radius: 46px;
        width: fit-content;
        margin: 20px 0;
        p{
            margin: 0;
        }
    }
}

.message {
    position: relative;
    margin-bottom: 20px;
    max-width: 350px;
    min-width: 60px;
    .messageWrp{
        margin: 0;
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 4px;
        box-shadow: 0px 2px 2px 0px rgba(235, 226, 226, 0.5);
    }
    h4{
        margin: 0;
        padding-top: 5px;;
        font-size: 10px;
        color:gray
    }
}


.message.right{
    align-self: flex-end;
    .messageWrp{
        margin: 0;
        font-size: 14px;
        padding: 7px 15px 7px 15px;
        background: #FEC627;
        border-radius: 17px 4px 17px 17px;
        color: black;
        font-weight: bold;
    }
    button{
        margin: 2px;
        background: #fec62766;

    }
    h4{
        text-align: right;
    }
    
}

.message.left{
    align-self: flex-start;
    .messageWrp{
        margin: 0;
        font-size: 14px;
        padding: 7px 15px 7px 15px;
        border-radius: 4px 17px 17px 17px;
        color: black;
        font-weight: bold;
        background: white;
    }
    
}

.messages-container {
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: transparent;
    height: 100%;
}


.chatcontainer {
    background-color: transparent;
    padding: 10px;
    position: relative;
    width: 100%;
    max-width: 100%;
    order: 2;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &__innerdiv{
        position: relative;
        display: flex;
        flex-direction: column;
    }
}

.chatcontainer>.ant-list-items {
    background-color: green;
}

.usertraxnform__field {
    width: 80%;
    background: #fff;
    color: #a3a3a3;
    font: inherit;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.1);
    border: 0;
    outline: 0;
    padding: 7px;
    margin-bottom: 10px;
}
.usertraxnform__field_bid {
    width: 50%;
    background: #fff;
    color: #030303;
    font: inherit;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.1);
    border: 0;
    outline: 0;
    padding: 7px;
    margin-bottom: 10px;
}

.newLeadButtonContainer
{ width: 100%;
display: flex;
 justify-content: flex-end;
 }

.inbox-wrapper{
    display: flex;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    overflow: hidden;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    @media screen and (max-width:780px) {
        height: calc(100vh - 120px);
    }
    .companylist{
        flex: 0 0 30%;
        max-width: 30%;
        overflow-y: auto;
        border-right: 1px solid #D7D7D7;
        position: relative;
        border-left:1px solid #D7D7D7;
        @media screen and (max-width:780px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .m-companylist{
        @media screen and (max-width:780px) {
            flex: 0 0 0% !important;
            max-width: 0% !important;
        }
    }
    .leadlist{
        flex: 0 0 100%;
        padding: 10px;
        // background: rgb(250,248,227);
        // background: linear-gradient(90deg, rgba(250,248,227,1) 32%, rgba(255,255,255,1) 89%);
        overflow-y: hidden;
        // margin-top: 5px;
    }
    .chatlist{
        flex: 0 0 70%;
        max-width: 70%;
        // background: rgb(250,248,227);
        // background: linear-gradient(90deg, rgba(250,248,227,1) 32%, rgba(255,255,255,1) 89%);
        overflow-y: hidden;
        // margin-top: 5px;
    }
    .m-chatlist{
        @media screen and (max-width:780px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.inbox-card{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding-left: 7px;
    column-gap: 10px;
    p{
        margin-bottom: 0;
    }
    .contentWrapper{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.post-card{
    padding-right: 8px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding-left: 7px;
    column-gap: 10px;
    p{
        margin-bottom: 0;
    }
    .contentWrapper{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.card3 {
    top: 0px;
    padding: 10px;
    position: relative;
    background-color: #ffffff;
    // border-radius: 4px;
    text-decoration: none;
    overflow: hidden;
    // border: 1px solid #f0f0f0;
    display: block;
}

.approve-reject-div{
    width: 50%;
}

.card3highlight{
    background: antiquewhite;
}

.inbox-wrapper .ant-list-items{
    padding:0
}

.titledate-div{
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
}

.card3:hover {
    // border: 1px solid #1F3E76;
    // box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    // z-index: 500;
    cursor: pointer;

}

.chats-wrapper{
    height: 100%;
    background: #F6F6F6;
    position: relative;
    display: flex;
    flex-direction: column;
    padding:10px
    // border-style: solid;
    // border-color: #e2e5eb;
}

.chatheader{
    order: 1;
    padding: 10px 20px;
    background: white;
    border-bottom: 1px solid #e2e5eb;
    display: flex;
    align-items: center;
    column-gap: 10px;
    h1{
        font-size: 15px;
        margin: 0;
        @media screen and (max-width:780px) {
            font-size: 14px;
        }
    }
    span{
        color: gray;
        // font-style: italic;
        // margin-left: 10px;
        font-size: 12px;
    }
    &__backicon{
        color: black !important;
        @media screen and (min-width:780px) {
            display: none;
        }
        
    }
}

.chatheaderwrapper{
    padding: 0 0 16px;
    height: calc(100vh - 64px);
    margin:-24px
}

.ant-page-header-content{
    padding-top:0 !important;
    height:100% !important
}

.sendchat{
    display: flex;
    width: 100%;
    order: 3;
    background: white;
    align-items: center;
    border: 0.6px solid #D7D7D7;
    .ant-input:focus, .ant-input-focused{
        border: none !important;
        box-shadow: 0 0 0 2px rgb(162 162 162 / 20%) !important;
    }
    &__text{
        resize: none;
        margin: 10px;
        outline: none;
        border: none;
        padding: 10px 65px 10px 100px;
        max-height: 50px;
        background: #F6F6F6;
        border-radius: 32px;
        :focus{
            border: none !important;
            box-shadow: 0 0 0 2px rgb(162 162 162 / 20%) !important;
        }
    }
    &__btn{
        right: 20px;
        position: absolute;
        padding: 0;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: white;
        margin-right: 10px;
        background: white;
        border: none;
    }
    &__initiatepay{
        left: 20px;
        position: absolute;
        padding: 0;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: white;
        margin-left: 10px;
        background: white;
        border: none;
        z-index: 1000;
    }
    &__attachment{
        left: 65px;
        position: absolute;
        padding: 0;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: white;
        margin-left: 10px;
        background: white;
        border: none;
        z-index: 1000;
    }
}

.product-div
{
    padding-right: 20px;
    width: 57%;
    overflow: scroll;
    height: 426px;
    margin-top: 36px;
}
.message-div{
    display: grid;
    grid-template-columns: 2fr 1fr;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    p{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.message-div-lead{
    display: grid;
    border: 1px solid #000000;
    border-radius: 10px; /* All corners have the same radius */
    box-shadow: 1px 1px 2px #888888;
    grid-template-columns: 2fr 1fr;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    margin-top: 10px;
    p{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.unreadMsgMap-msg{
    background: #202c33;
    border-radius: 50%;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.edit-company-logo{
    display:grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 10px;
}

.inbox-header{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    // background: #e2e5eb;
    padding: 10px 0px 00px;
    // height: 50px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    // z-index: 1000;
    background: white;
    border-bottom: 1px solid #d7d7d7;
    .tabs{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        margin-top: 5px;
        .tab{
            padding: 3px 10px;
            border: 1px solid #FECB03;
            background: #fffcee;
            font-size: 12px;
            border-radius: 10px 10px 0 0;
            cursor: pointer;
        }
        .active{
            background:#FECB03
        }
    }

}
 
.commentBox{
    width: 100%;
}

.listWrapper{
    margin-top: 70px;
    overflow-y: auto;
    height: calc(100% - 95px);
}

.initiatepaymentpopupWrp{
    position: absolute;
    // top: 100%;
    // left: 25px;
    width: 300px;
    z-index: 1000;
    background: white;
    border-radius: 4px;
    padding: 20px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    border: 1px solid #d7d7d7;
    transition: all 1.2s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .closeicon{
        text-align: right;
        position: absolute;
        right: 6px;
        top: 5px;
        color: gray;
        font-size: 10px;
        cursor: pointer;
    }
    .init_payment_btn{
        font-size: 14px;
        padding: 7px 15px 7px 15px;
        background: #FECB03;
        border-radius: 17px;
        color: black;
        font-weight: bold;
        margin: 20px 0 20px;
    }
    .init_payment_desc{
        width: 100%;
        outline: none;
        border: 0;
        background: #F0F0F0;
        box-shadow: 0px 4px 60px rgb(0 0 0 / 6%);
        border-radius: 8px;
        resize: none;
        padding: 10px;
        height: 160px;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #3C3C3C;
    }
    .init_payment_input{
        margin-top: 40px;
        padding: 10px;
        outline: none;
        border: 0;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        ::placeholder{
            font-size: 15px !important;
        }
    }
}

.filterspopupWrp{
    position: absolute;
    top: 100%;
    left: 25px;
    width: 80%;
    z-index: 1000;
    background: white;
    border-radius: 4px;
    padding: 20px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    border: 1px solid #d7d7d7;
    transition: all 1.2s;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }

.filterspopupWrp .filtersScrollArea{
    height: 25rem;
    overflow: auto;
}

.filterspopupWrp .seperate-filter-wrp{
    margin-bottom: 20px;
    h4{
        font-weight: bold;
    }
}

.filtersApplybtn{
   // width: 100%;
    background: #fec627;
    font-weight: bold;
    border: 0;
    margin: 5px;
}



.filters1Applybtn{
    width: 100%;
    background: #fec627;
    font-weight: bold;
    border: 0;
}

.filterspopupWrp .filters-chip{
    font-size: 10px;
    background: #a9a7a7;
    border-radius: 19px;
    color: white;
    padding: 5px 12px;
    height: 25px;
    text-align: center;
    margin: 10px;
    width: fit-content;
    cursor: pointer;
}

.filterspopupWrp .checked{
    background: #fec627 !important;
}

// .switch-wrapper{
//     display: flex;
//     align-items: center;
//     justify-content: end;
//     margin-right: 10px;
//     font-style: italic;
//     span{
//         // font-weight: bold;
//         margin-right: 10px;
//     }
// }

.post-input{
    margin-top: 12px;
    margin-bottom: 10px;
    width: 50%;
    height: 30px;
    padding: 2px;
    border-radius: 8px;
    border-width: 2px;
    border-color: lightgrey;
}

.approve-reject-btn{
    height: 30px;
    width: 50%;
    font-size: 10px;
    background: #FFFCEE;
    border: 0.4px solid #FECB03;
    border-radius: 34px;
    color: #FECB03;
    padding: 0 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.callback-btn{
    font-size: 10px;
    background: #FFFCEE;
    border: 0.4px solid #FECB03;
    border-radius: 34px;
    color: #FECB03;
    height: 22px;
    padding: 0 6px;
    margin: 0;
}

.calling-wrapper{
    border: 1px solid;
    position: absolute;
    top: 10%;
    right: 10px;
    z-index: 1000;
    width: 450px;
    height: 300px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    background: black;
    color: white;
}

.calling-users{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin: 20px 0;
}

.callend{
    width: fit-content;
    margin: 30px auto;
    padding: 10px;
    background: red;
    border-radius: 50%;
}

.accept-call-wrapper{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
}

.accept-call-btn{
    background: lightgreen;
    color: white;
    font-weight: bold;
}


.reject-call-btn{
    background: red;
    color: white;
    font-weight: bold;
}

.form {
    // display: grid;
    // grid-template-columns: 1fr 4fr;
    &__label{
        padding: 20px;
    }
    &__fields{
        padding: 0 20px;
        text{
            // label
            font-size: 16px;
            color: #555151;
            font-weight: 600;            
        }
        textarea{
            width: -webkit-fill-available;
            border: 0;
            outline: 0;
            min-height: 20px;
        }
    }
    &__input{
        width: -webkit-fill-available;
        border: 0;
        outline: 0;
        min-height: 20px;
    }
    &__field{
        width: 360px;
		background: #fff;
		color: #a3a3a3;
		font: inherit;
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0 , .1);
		border: 0;
		outline: 0;
		padding: 14px 18px;
        margin:20px 0;
    }
    &__btn{
        box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
        border-radius: 2px;
        padding: 9px 36px;
        background: #f7d708;
        border: none;
        font-weight: 600;
        letter-spacing: 1px;
        cursor: pointer;
        margin-left: 15px;
    }
    &__autocomplete{
        input{
            border: 1px solid lightgray;
            border-radius: 5px;
            width: 360px;
            background: #fff;
            color: #a3a3a3;
            font: inherit;
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0 , .1);
            border: 0;
            outline: 0;
            padding: 14px 18px;
            margin:20px 0;
        }
        
        .autocomplete-menu{
            padding: 5px 10px;
            cursor: pointer;
        }
    }
    &__dropdown{
        border: 1px solid lightgray;
        border-radius: 5px;
        width: 360px;
        background: #fff;
        color: #a3a3a3;
        font: inherit;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0 , .1);
        border: 0;
        outline: 0;
        padding:6px 6px;
        margin:20px 0;
        
        .ant-select-selector{
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
        }

        .ant-select-selection-item{
            color: #a3a3a3;
        }
    }
}

.pagination-list{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid lightgray;
    align-items: center;
    height:45px
}

.magicLink-btn{
    border: 1px solid #FECB03;
    width: 80%;
    height: 45px;
    background: #FECB03;
    border-radius: 6px;
    font-weight: bold;
    font-size: 15px;
    color: white;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 30px 0;
}

.login-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 16px;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
    margin: auto;
    @media screen and (max-width:740px) {
            display: grid;
            grid-template-rows: 1fr 1fr;
            border-radius: 0;
            grid-template-columns: 1fr;
    }
    // display: "flex",
    // alignItems: "center",
    // flexDirection: "column",
    // justifyContent:"center",
    // alignContent: "flex-end"

    .login-image{
        @media screen and (max-width:740px) {
            border-radius: 0;
        }
        h1{
            color:white;
            font-size:30px
        }
        h2{
            color:white
        }
        p{
            color:lightslategray
        }
        padding: 40px;
        background: #000814;
        color: white;
        border-radius: 16px 0 0px 16px;
        .benam-chat-img{
            width: 300px;
            margin: 20px 0;
            @media screen and (max-width:740px) {
                width: 70px;
            }
        }
        .benam-logo-img{  
            width: 200px;
            display: block;
        }
    }

    .login-btn-box{
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        place-content: flex-end center;
        text-align: center;
    }
}


.attachmentPopup{
    background: #fffcee;
    .attachFile{
        width: 100%;
        margin: 38px 0;
        border: 1px solid #fecb03;
        border-radius: 3px;
        background: white;
    }
}

.allOrdersWrp{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    padding: 7px 15px 7px 15px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 30px;
    @media screen and (max-width:780px) {
        grid-template-columns: 1fr;
    }
    .orderInfo{
        p{
            font-weight: bold;
        }
        span{
            font-weight: 400;
        }
    }
}

.multiimages{
    display: flex;
    flex-direction: column;
    row-gap: 22px;
}

.otp-input{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    input{
        width: 3em !important;
        height: 3em;
        text-align: center !important;
        outline: none;
        border: 1px solid darkslategray;
        margin: 0 6px;
        font-weight: bold;
        border-radius: 3px 
    }
}

.totalResults{
    margin: 0;
    text-align: right;
    font-size: 12px;
    padding: 0;
    //bold 
    font-weight: bold;
}
.top-border {
    border-top: 1px solid #a09b9b; /* Adjust the color as needed */
    width: 100%;
    box-sizing: border-box; /* Includes the border in the width calculation */
    padding-top: 10px;
  }

.lead-card {
   
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
}
.modal {
    position: fixed;
    top: 30vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    overflow: hidden;
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #e0e0e0;
        background-color: #ffffff;

        .close{
            cursor: pointer;
        }
        .link-container{
            padding: 10px 0px;
            a{
                font-weight: 600;
                padding-right: 10px;
            }
            text{
                font-size: 14px;
                font-weight: 500;
                padding-left: 5px;
            }

        }
        text{
            font-size: 18px;
            font-weight: bold;
        }
    }
}

